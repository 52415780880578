import Swiper, { Autoplay } from 'swiper';

Swiper.use([Autoplay]);

if (document.querySelector('[data-announcement-slider]')) {
    // eslint-disable-next-line no-unused-vars
    const slider = new Swiper('[data-announcement-slider]', {
        speed: 300,
        allowTouchMove: false,
        slidesPerView: 'auto',
        centeredSlides: true,
        autoplay: {
            delay: 5000,
            disableOnInteraction: false,
        },
        breakpoints: {
            700: {
                speed: 1000,
            },
        },
    });
}
